html {
  background-color: #1d0000;
  color: #fff;
  font-family: 'Roboto Condensed', sans-serif;
}

h2 {
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 2em;
}
a,
a:visited {
  color: #ee0048;
}

.navigateRooms {
  display: flex;
  justify-content: space-evenly;
  padding: 2.5em 0;
}

.explainer {
  font-size: 3em;
  margin: -69px 0 0 0;
  color: white;
  line-height: 1em;
}

.flourish {
  width: 40%;
  padding: 3em 0;
}

.titleSection h2 {
  margin: 0;
}

.disabled,
.disabled:hover {
  background: #4d4d4d;
}

.roomBox {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.roomBox img {
  width: 130px;
  min-height: 130px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #ee0048;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.masthead {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 50vh; /* if you don't want it to take up the full screen, reduce this number */
  overflow: hidden;
  padding-bottom: 10vh;
  background-size: cover !important;
  background: radial-gradient(
      ellipse at center,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0) 35%,
      rgba(0, 0, 0, 0.65) 100%
    ),
    url(enjoyingtheshow.jpg) no-repeat center center scroll;
}

.masthead h1 {
  font-style: normal;
  font-weight: bold;
  color: #fff;
  font-size: 11vmin;
  letter-spacing: 0.03em;
  line-height: 1;
  text-shadow: 1px 2px 4px rgba(0, 0, 0, 0.8);
  margin-bottom: 9px;
  animation: glowy 1600ms ease-out infinite alternate;
  font-family: 'Montserrat', sans-serif;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: rgba(157, 34, 34, 0.7);
  padding-bottom: 0.2em;
}

button {
  background: #ee0048;
  transition: background ease 0.25s;
  border-radius: 5px;
  display: inline-block;
  /* border: none; */
  /* border: 2px solid; */
  padding: 0.75rem 1.5rem;
  margin: 0;
  text-decoration: none;
  color: #ffffff;
  font-size: 1.2rem;
  cursor: pointer;
  text-align: center;
  -webkit-appearance: none;
  -moz-appearance: none;
}
button:hover {
  background: #ff2566;
}
button:focus {
  outline: 1px solid #fff;
  outline-offset: -4px;
}

@keyframes glowy {
  0% {
    text-shadow: 0 0 10px #fff, 0 0 20px #fff, 0 0 30px #a86969,
      0 0 40px #a00c0c, 0 0 50px #a00c0c, 0 0 60px #a00c0c, 0 0 70px #a00c0c;
  }
  100% {
    text-shadow: 0 0 0px #fff, 0 0 20px #fff, 0 0 20px #a00c0c, 0 0 40px #a00c0c,
      0 0 50px #a00c0c, 0 0 60px #a00c0c, 0 0 70px #a00c0c;
  }
}

.page-head {
  position: fixed;
  background-color: rgba(157, 34, 34, 0.7);
  color: white;
}

.form__group {
  position: relative;
  padding: 15px 0 0;
  margin: 0 0 10px;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid #9b9b9b;
  outline: 0;
  font-size: 1.3rem;
  color: #fff;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;
}

.form__field::placeholder {
  color: transparent;
}

.form__field:placeholder-shown ~ .form__label {
  font-size: 1.3rem;
  cursor: text;
  top: 20px;
}

.form__field:focus ~ .form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #ee0048;
  font-weight: 700;
}

.form__field:focus {
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, #ee0048, #a00c0c);
  border-image-slice: 1;
}
.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: #9b9b9b;
  font-weight: 700;
  z-index: -1;
}
/* reset input */

.form__field:required,
.form__field:invalid {
  box-shadow: none;
}

.footer {
  display: flex;
  justify-content: space-around;
  background-color: black;
  height: 60px;
  margin-top: 150px;
  bottom: 0;
  position: relative;
  width: 100%;
  color: #9b9b9b;
}

.footerImage {
  position: relative;
  height: 120px;
  top: -60px;
}

#explainerSection {
  text-align: center;
}

#explainerSection p strong {
  font-family: 'Montserrat', sans-serif;
  color: #ee0048;
}

#explainerHeaderImage {
  width: 30% !important;
}

#explainerSection p {
  padding: 0.5em 3em;
  font-family: 'Roboto Condensed', sans-serif;
  font-size: 1.5em;
}

#explainerSection img {
  width: 10%;
}

.specialThanks {
  font-family: 'Montserrat', sans-serif;
  margin: 0 0 -30px 0;
  padding-top: 40px;
}

.thanks {
  font-size: 0.85em;
  color: #9b9b9b;
}

.crazyGrads {
  background: linear-gradient(180deg, #1d0000, #490606);
  background-size: 400% 400%;

  -webkit-animation: gradientPulse 16s ease infinite;
  -moz-animation: gradientPulse 16s ease infinite;
  animation: gradientPulse 16s ease infinite;
}

@-webkit-keyframes gradientPulse {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@-moz-keyframes gradientPulse {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}
@keyframes gradientPulse {
  0% {
    background-position: 51% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 51% 0%;
  }
}

.room {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

#overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

#inputVideo {
  background-color: rgba(255, 0, 0, 0.05);
  height: auto;
  width: 100%;
  background-image: url('/ets.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

#captureContainer {
  position: relative;
  flex: 1;
  width: 50%;
  margin: auto;
}

.resultFramer {
  flex: 1;
  width: 100vh;
  max-width: 100%;
  margin: -25px auto;
  background-image: url('/frameset.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}

.stagePlace {
  position: absolute;
  z-index: -1;
}

.centerme {
  text-align: center;
}

hr {
  overflow: visible; /* For IE */
  padding: 0;
  margin: 100px auto;
  border: none;
  border-top: medium double #c33f38;
  color: #c33f38;
  text-align: center;
  width: 66%;
}
hr:after {
  padding: 100px 0;
  content: '§';
  display: inline-block;
  position: relative;
  top: -0.7em;
  font-size: 1.5em;
  padding: 0 0.25em;
}

.VictoryContainer {
  width: 90% !important;
  margin: auto;
}

.roomEmpty {
  text-align: center;
  position: absolute;
  margin: 10em 0 0 -50vw;
  left: 50vw;
  width: 100%;
  color: #ee0048;
  font-size: 1.5em;
}

.bb,
.bb::before,
.bb::after {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bb {
  width: 200px;
  height: 200px;
  margin: auto;
  color: #ee0048;
  box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0.5);
}
.bb::before,
.bb::after {
  content: '';
  z-index: -1;
  margin: -5%;
  box-shadow: inset 0 0 0 2px;
  animation: clipMe 8s linear infinite;
}
.bb::before {
  animation-delay: -4s;
}
.bb:hover::after,
.bb:hover::before {
  background-color: rgba(255, 0, 0, 0.3);
}

@keyframes clipMe {
  0%,
  100% {
    clip: rect(0px, 220px, 2px, 0px);
  }
  25% {
    clip: rect(0px, 2px, 220px, 0px);
  }
  50% {
    clip: rect(218px, 220px, 220px, 0px);
  }
  75% {
    clip: rect(0px, 220px, 220px, 218px);
  }
}

.roomReport {
  text-align: center;
  margin-bottom: -2em;
  color: #c33f38;
}

.videoDemo {
  width: 100%;
  margin: 80px auto;
}

.videoDemo video {
  width: 50%;
  padding-bottom: 15px;
}

.videoDemo hr {
  margin: 20px auto;
}
